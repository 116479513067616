function format(date, fmt = "yyyy-MM-dd hh:mm:ss") {

	if(typeof date === 'string'){
		date = toDate(date);
	}else if(typeof date === 'number'){
		date = new Date(date);
	}


	var o = {
		"M+": date.getMonth() + 1, //月份

		"d+": date.getDate(), //日

		"h+": date.getHours(), //小时

		"m+": date.getMinutes(), //分

		"s+": date.getSeconds(), //秒

		"q+": Math.floor((date.getMonth() + 3) / 3), //季度

		S: date.getMilliseconds(), //毫秒
	};

	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(
			RegExp.$1,
			(date.getFullYear() + "").substr(4 - RegExp.$1.length)
		);
	}

	for (var k in o) {
		if (new RegExp("(" + k + ")").test(fmt)) {
			fmt = fmt.replace(
				RegExp.$1,
				RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
			);
		}
	}

	return fmt;
}

export const toDate = (timeStr) => {
	let expires_time = timeStr.substring(0, 19);
	expires_time = expires_time.replace(/-/g, "/");
	return new Date(expires_time);
};

// timeStr=12:30
export function getTimeNumByStr(timeStr) {
	const list = timeStr.split(":");
	const hours = parseInt(list[0] || 0) || 0;

	const minutes = parseInt(list[1] || 0) || 0;

	return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
}

// 在时间之间 startTime=10:30    endTime=12:30 curTime=14:30
export function hasBetweenTimeStr(startTime, endTime, curTime, week="") {
	if (!startTime || !endTime) {
		return false;
	}
  if(week){
    const weekStr = week + '';
    const curWeekStr = new Date().getDay() + '';
    if(weekStr != curWeekStr){
      return false;
    }
  }
	const startTimeNum = getTimeNumByStr(startTime);
	const endTimeNum = getTimeNumByStr(endTime);
	const curTimeNum = getTimeNumByStr(curTime);

	if (curTimeNum > startTimeNum && curTimeNum < endTimeNum) {
		return true;
	}
	return false;
}

// 即将到时间
export function hasWhillTimeStr(
	startTime,
	endTime,
	curTime,
  week="",
	preTime = 30 * 60 * 1000
) {
	if (!startTime) {
		return false;
	}

  if(week){
    const weekStr = week + '';
    const curWeekStr = new Date().getDay() + '';
    if(weekStr != curWeekStr){
      return false;
    }
  }

	const startTimeNum = getTimeNumByStr(startTime);
	const curTimeNum = getTimeNumByStr(curTime);
	const disTime = curTimeNum - startTimeNum;
	if (disTime > 0 && disTime < preTime) {
		return true;
	}
	return false;
}


// 两个时间之间的差值 返回毫秒
export function getTimeDiff(startTime, endTime){
	let startTimeDate = null;
	let endTimeDate = null;

	if(typeof startTime === 'string'){
		startTimeDate = toDate(startTime);
	}else if(typeof startTime === 'number'){
		startTimeDate = new Date(startTime);
	}else{
		startTimeDate = startTime || new Date();
	}


	if(typeof endTime === 'string'){
		endTimeDate = toDate(endTime);
	}else if(typeof endTime === 'number'){
		endTimeDate = new Date(endTime);
	}else{
		endTimeDate = endTime || new Date();;
	}

	return endTimeDate.getTime() - startTimeDate.getTime();
}


// 两个时间之间的差值 返回天数
export function getTimeDiffDay(startTime, endTime, minValue = undefined){
	const diffTime = getTimeDiff(startTime, endTime);
	const day = Math.ceil(diffTime/1000/60/60/24);

	if(typeof minValue != "undefined" &&  day < minValue){
		return minValue;
	}
	return day;
}


export default {
  format,
	toDate,
	getTimeNumByStr,
	hasBetweenTimeStr,
	hasWhillTimeStr,
	getTimeDiff,
	getTimeDiffDay
};
