import axios from 'axios'
import store from '@/store'
import util from "./util.js";
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// create an axios instance
const service = axios.create({
	//baseURL: "https://zhuxue-api.cnzxzx.cn", //  微信线上接口地址
	//baseURL: "https://zhuxue-api.cnzxzx.cn/prod-api", //  H5/Android/iOS 线上地址
	// baseURL: "http://localhost:8083", // 本地开发环境地址
	baseURL: util.getApiHost(), //  "https://zhuxue-api.cnzxzx.cn", // 本地开发环境地址
	
	withCredentials: true, // send cookies when cross-domain requests
	timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		// do something before request is sent
		if (config.headers.auth !== false) { // 判断请求是否需要认证
			const token = uni.getStorageSync('token')
			if (token) {
				config.headers['Authorization'] = "Bearer " + token;
			}
		}
		if(config.headers){
			delete config.headers.auth
		}

		const params = config.params || {};
		for (const key in params) {
			if (Object.hasOwnProperty.call(params, key)) {

				const element = params[key];
				if(element === "null" || element === null || element === 'undefined' || element === undefined){
					delete config.params[key];
				}
				
			}
		}
		
		return config
	},
	error => {
		// do something with request error
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

let reqCount = 0;

service.defaults.adapter = function(config) {
	return new Promise((resolve, reject) => {
		var settle = require('axios/lib/core/settle');
		var buildURL = require('axios/lib/helpers/buildURL');

		reqCount ++;

		let curReqCount = reqCount;


		console.log(curReqCount, config.method.toUpperCase(), config.url);

		uni.request({
			method: config.method.toUpperCase(),
			url: config.baseURL + buildURL(config.url, config.params, config.paramsSerializer),
			header: config.headers,
			data: config.data,
			dataType: config.dataType,
			responseType: config.responseType,
			sslVerify: config.sslVerify,
			complete: function complete(response) {
				console.group && console.group(curReqCount);
				console.log( '请求地址：', config.method.toUpperCase(), config.baseURL + buildURL(config.url, config.params, config.paramsSerializer));
				console.log( 'params', config.params);
				console.log( '参数data：', config.data);
				console.log( '结果：', response.data);
				console.groupEnd && console.groupEnd(curReqCount);
				response = {
					data: response.data,
					status: response.statusCode,
					errMsg: response.errMsg,
					header: response.header,
					config: config
				};
        try {
          settle(resolve, reject, response);
        } catch (error) {
          console.log('settle ======');
          console.error(error)
        }
				
			}
		})
	})
}


// response interceptor
service.interceptors.response.use(({
		config,
		data
	}) => {
		return codeHandle(data);
		// return Promise.reject(data);
	},
	error => {
		const {
			code,
			msg
		} = error.response.data

		return codeHandle(error.response.data);
	}
)



function codeHandle(data){
	data = data || {};
	const codeStr = data.code + '';
	if(codeStr == '0' || codeStr == '200'){
		return data;
	}
	let msg = data.msg || data.detailMessage || `错误码：${codeStr}`;
	switch(codeStr){
		case '500':
			uni.showToast({
				title: msg,
				icon: 'none'
			});
			break;
		case '401':
			onLoginInvalidHandle();
			break;
		case '1004003004':
			util.clearStorageSync();
			break;
		case '1002000006':
			util.clearStorageSync();
			break;
		default:{
			uni.showToast({
				title: msg,
				icon: 'none'
			});
      console.log('codeHandle1 codeStr', data);
			return Promise.reject(data);
		}
	}

   console.log('codeHandle2 codeStr', data);
	return Promise.reject(data);
}


// 登录失效
function onLoginInvalidHandle(){
	uni.showToast({
		title: '会话已过期，请重新登录',
		duration: 3000,
		success() {
			let routes = getCurrentPages();
			if(routes[routes.length - 1] && routes[routes.length - 1].route == 'pages/auth/login'){
				return;
			}
			uni.navigateTo({
				url: `/pages/auth/login`,
			});
		},
		complete(){

		},
	})
}

export default service
