export function setStorageSync(key, obj){
	let str = "";
	try {
		str = JSON.stringify(obj);
	} catch (error) {
		str = obj;
	}
	uni.setStorageSync(key, str);
}

export function getStorageSync(key){
	let str = uni.getStorageSync(key);
	let obj = "";
	try {
		obj = JSON.parse(str);
	} catch (error) {
		obj = obj;
	}
	
	if(obj == "null" || obj == "undefined"){
		return "";
	}
	return obj;
}

export default {
	setStorageSync,
	getStorageSync
};