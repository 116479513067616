import request from '@/utils/request'
import store from '../store'
const buildURL = require('axios/lib/helpers/buildURL');

const curWebHost = "https://admin.cnzxzx.cn";

// const curWebHost = "https://zhuxue-h5.cnzxzx.cn";
const curHostApi = "https://zhuxue-api.cnzxzx.cn";
// const curHostApi = "http://localhost:8083";

const curH5Host = "https://h5.cnzxzx.cn";



let apiHost = uni.getStorageSync('apihost');
let webHost = uni.getStorageSync('webhost');


const setApiHost = (apihost)=>{
	uni.setStorageSync('apihost', apihost);
	apiHost = apihost;
	// console.log('request apihost:', request.defaults);
	request.defaults.baseURL = getApiHost();
}

const getApiHost = ()=>{
	return apiHost || curHostApi;
}

const setWebHost = (webhost)=>{
	uni.setStorageSync('webhost', webhost);
	webHost = webhost;
}

const getWebHost = ()=>{
	return webHost || curWebHost;
}

const getApiUrl =(url)=>{
	
	const baseURL = getApiHost() + '';
	return baseURL + url;
}

const getAssetUrl = (url)=>{
  // default_logo
  if(!url){
    return getWebAssetUrl("/static/default_logo.png");
  }
	return url;
}

const getWebAssetUrl = (url)=>{
	return getWebHost() + "/wxapp" + url;
}

const getAvatar = (url)=>{
	return url || getWebAssetUrl("/static/member/avatra.png");

}

const getTime = (time)=>{
      
	if(!time){
	  return null;
	}
	if(typeof time == 'string'){
	  time = time.split('-').join('/')
	  return Date.parse(new Date(time));
	} else if(typeof time == 'Object'){
		return Date.parse(time);
	}
   return time
}

const clearStorageSync = ()=>{

	let apiHost = uni.getStorageSync('apihost');
	let webHost = uni.getStorageSync('webhost');

	uni.clearStorageSync();

	uni.setStorageSync('apihost', apiHost);

	uni.setStorageSync('webhost', webHost);

}


const formatTime = date => {
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()

	return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

const formatNumber = n => {
	n = n.toString()
	return n[1] ? n : '0' + n
}

const durationForHuman = duration => {
	let minutes = parseInt(duration / 60);
	let seconds = duration % 10;
	// console.log(duration, minutes, seconds);
	if (minutes.length === 0) {
		minutes = '0' + minutes.toString();
	}
	if (seconds.length === 0) {
		seconds = '0' + seconds.toString();
	}
	return minutes + '分钟' + seconds + '秒';
}

const loginCheck = () => {
	if (!uni.getStorageSync('token')) {
		uni.showModal({
			title: "提示",
			content: "登录后内容更精彩，是否前往登录?",
			success(res) {
				if (res.confirm) {
					uni.navigateTo({
						url: '/pages/auth/login',
					})
				}
			}
		})
	}
}

const relaunchTo = (redirect)=>{
	uni.reLaunch({
		url: redirect || "/pages/index/index",
		fail:(e)=>{
			back(redirect);
		}
	})
}

  const back = (redirect)=>{

	if (redirect) {
		uni.redirectTo({
		  url: redirect,
		  fail: (e) => {
			uni.switchTab({
			  url: "/pages/index/index",
			});
		  },
		});
	  } else {
		const routes = getCurrentPages();
		if(routes.length > 1){
		  uni.navigateBack({
			delta: 1,
		  });
		}else{
		   uni.switchTab({
			  url: "/pages/index/index",
			});
		}
	  }

  }
const go = (page, auth = false) => {
	console.log('go:', page, auth);
	if (auth && !uni.getStorageSync('token')) {
		uni.showModal({
			title: "提示",
			content: "登录后内容更精彩，是否前往登录?",
			success(res) {
				if (res.confirm) {
					uni.navigateTo({
						url: '/pages/auth/login?redirect=' + encodeURIComponent(page),
					})
				}
			}
		})
		return
	}


	uni.navigateTo({
		url: page,
		success:()=>{

		},
		fail:(e)=>{
			console.log('navigateTo 跳转失败开始尝试 switchTab', e, page);
			uni.switchTab({
				url: page,
				fail:(e)=>{
					console.log('switchTab跳转失败', e, page);
				}
			});
		}
	})
}

const isIos = () => {
	let info = uni.getSystemInfoSync();
	return info.platform === 'ios';
}

const isAuthentication = ()=>{

	
	return false;
}

const showLoading = async (title="加载中...", fun=null)=>{
	uni.showLoading({
		title: title,
		mask: true,
		success: ()=>{},
		fail:()=>{},
		complete:()=>{}
	});
	if(fun){
		try {
			await fun();
			uni.hideLoading();
		} catch (error) {
			uni.hideLoading();
			console.error(error);
			showToast(error.msg || error.message || JSON.stringify(error));
			Promise.reject(error);
		}
	}
}

const hideLoading = ()=>{
	uni.hideLoading();
}

const showToast = (title, mask=false)=>{
	uni.showToast({
		title: title,
		icon: "none", // 'loading',
		mask: mask,
		// image
		duration: 3000,
		position: "center", // top center bottom
		success: ()=>{},
		fail:()=>{},
		complete:()=>{}
	})

	// uni.hideToast();
}


const makePhoneCall = async(tel)=>{
	return new Promise((resolve, reject) => {
		uni.makePhoneCall({
			phoneNumber: tel,
			success:()=>{
				console.log('拨打电话');
				resolve(tel);
			},
			fail:()=>{
				console.log('拨打电话失败，准备复制电话号码');
				uni.setClipboardData({
					data: tel,
					success: ()=>{
						console.log('success');
						resolve(tel);
						showToast('复制成功：'+ tel);
					},
					fail:(e)=>{
						reject(e);
					}
				});
			}
		})
	})

	
}

// 确认对话框
const confirm = (title, content)=>{
	return new Promise((resolve, reject)=>{
		uni.showModal({
			title: title,
			content: content || title,
			success(res) {
				if (res.confirm) {
					resolve(res);
				}else{
					reject(res);
				}
			},
			fail(res){
				reject(res);
			}
		})
	})
	
	
}

const setAdmin = (isAdmin)=>{
	if(isAdmin){
		uni.setStorageSync('isAdmin', 1);
	}else{
		uni.setStorageSync('isAdmin', '');
	}
}

// 判断是否是管理员
const hasAdmin = ()=>{
	const isAdmin = uni.getStorageSync('isAdmin');
	if(isAdmin == 1){
		return true;
	}
	return false;
}

const getAdmin = ()=>{
	return hasAdmin();
}

// 获取当前选中用户的userId
const getUserId = (userId=0)=>{
	if(userId && userId != "null" && userId != 'undefined' && userId != "0"){
		return userId;
	}
	if(hasAdmin()){
		return store.state.user.userId;
	}
	if(store.state.user.curUserInfo){
		return store.state.user.curUserInfo.userId;
	}
	return null;
}

const getUserInfo = ()=>{

	if(hasAdmin()){
		return store.state.user.userInfo;
	}
	if(store.state.user.curUserInfo){
		return store.state.user.curUserInfo;
	}
	return null;
}

const getCompanyId = (companyId)=>{
	if(companyId && companyId != "null" && companyId != 'undefined' && companyId != "0"){
		return parseInt(companyId);
	}
	return getCompanyInfo().companyId || null;
}


const getCompanyInfo = ()=>{
	if(store.state.user.curCompanyInfo && store.state.user.curCompanyInfo.companyId){
		return store.state.user.curCompanyInfo;
	}

	return {
		companyId: 0,
		companyName: "在线助学"
	};
}


const getNum = (id)=>{
	if(id && id != "null" && id != 'undefined' && id != "0"){
		const num = parseInt(id, 10);
		if(isNaN(num)){
			return null;
		}
		return num || null;
	}

	return null;
}

const getBool = (bool)=>{
	if(bool == "null" || bool == 'undefined' || bool == "false" || bool == "0" || !bool){
		return false;
	}

	return true;
}

const parseStrToList = (str)=>{
	try {
		return JSON.parse(str);
	} catch (error) {
		
	}
	return [];
}

const parseListToStr = (list)=>{
	try {
		return JSON.stringify(list);
	} catch (error) {
		
	}
	return "[]";
}

const parseUrl = (url) => {
	if (!url) {
		return {};
	}

	var theRequest = new Object();
	const index = url.indexOf("?");
	if (index != -1) {
		var str = url.substr(index + 1);
		var strs = str.split("&");
		for (var i = 0; i < strs.length; i++) {
			theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
		}
	}

	return theRequest;

}

/**
 * 获取url
 * @param {*} obj.baseURL
 * @param {*} obj.url 
 * @param {*} obj.params 
 * @param {*} obj.paramsSerializer  
 */
const getUrlQueryByObj = (obj)=>{
	return buildURL(obj.url, obj.params, obj.paramsSerializer);
	// return obj.baseURL + buildURL(obj.url, obj.params, obj.paramsSerializer);
}

/**
 * 
 * @param {*} options.recordId rId  recordId
 * @param {*} options.parentRecordId prId  parentRecordId
 * @param {*} options.pageParentId ppId  pageParentId
 * @param {*} options.shareId sId  shareId
 * @param {*} options.keywords kwds  keywords
 * @param {*} pageId 当前页面id
 * @returns {
 * pageId,
 * url,
 * recordId,
 * parentRecordId,
 * pageParentId,
 * shareId,
 * keywords
 * }
 */
const getShareParams = (options, pageId) => {
	const q = decodeURIComponent(options.q || '') // 获取到二维码原始链接内容
	const scancode_time = parseInt(options.scancode_time) // 获取用户扫码时间 UNIX 时间戳
	let urlObj = {};

	if(q){
		urlObj = parseUrl(q) || {};
	}

	var pages = getCurrentPages(); 				//获取加载的页面
	var currentPage = pages[pages.length - 1]   //获取当前页面的对象
	const url =  currentPage.fullPath || (currentPage.$page && currentPage.$page.fullPath) || getUrlQueryByObj({url:currentPage.route, params: currentPage.options}); //获取页面url
	const id = urlObj.id || options.id; 
	const recordId = urlObj.rId || options.rId || urlObj.recordId || options.recordId; // 浏览器记录id
	const parentRecordId = urlObj.prId || options.prId || urlObj.parentRecordId || options.parentRecordId;
	const pageParentId = urlObj.ppId || options.ppId || urlObj.pageParentId || options.pageParentId || urlObj.pId || options.pId || urlObj.pageId || options.pageId;
	const shareId = urlObj.sId || options.sId || urlObj.shareId || options.shareId;
	const keywords = urlObj.kwds || options.kwds || urlObj.keywords || options.keywords;
	const templateInstanceId = urlObj.tiId || options.tiId || urlObj.templateInstanceId || options.templateInstanceId;
	const companyId = urlObj.cid || options.cid || urlObj.companyId || options.companyId;
	const classId = urlObj.cls || options.cls || urlObj.classId || options.classId;
	const courseId = urlObj.cos || options.cos || urlObj.courseId || options.courseId;
	const userId = urlObj.uid || options.uid || urlObj.userId || options.userId;
	const fromUserId = urlObj.fuid || options.fuid || urlObj.fromUserId || options.fromUserId;


	return {
		pageId: pageId,
		id: id,
		url: url,
		recordId: recordId,
		parentRecordId: parentRecordId,
		pageParentId: pageParentId,
		shareId: shareId,
		keywords: keywords,
		templateInstanceId: templateInstanceId,
		companyId: companyId,
		classId: classId,
		courseId: courseId,
		userId: userId,
		fromUserId: fromUserId
	};

}

const getDefaultCompanyInfo = ()=>{
	return {
		companyId: 0,
		companyName: "在线助学"
	}
}

// const curH5Host = "https://h5.cnzxzx.cn";


module.exports = {
	getDefaultCompanyInfo,
	formatTime: formatTime,
	durationForHuman: durationForHuman,
	loginCheck: loginCheck,
	go: go,
	back: back,
	relaunchTo: relaunchTo,
	isIos: isIos,
	isAuthentication,
	getTime: getTime,
	getApiUrl: getApiUrl,
	getAssetUrl: getAssetUrl,
	getWebAssetUrl: getWebAssetUrl,
	setApiHost: setApiHost,
	getApiHost: getApiHost,
	setWebHost: setWebHost,
	getWebHost: getWebHost,
	clearStorageSync: clearStorageSync,
	getAvatar: getAvatar,
	showLoading: showLoading,
	hideLoading: hideLoading,
	showToast: showToast,
	confirm: confirm,
	makePhoneCall,
	hasAdmin,
	setAdmin,
	getAdmin,
	getUserId,
	getUserInfo,
	getCompanyId,
	getCompanyInfo,
	getNum,
	getBool,
	parseListToStr,
	parseStrToList,
	parseUrl,
	getShareParams
}