import 'uni-pages';import 'uni-h5';import App from './App.vue'
import uView from "uview-ui";
import store from './store'
import util from './utils/util';
import time from './utils/time.js';
import auth from './utils/auth'
import {encodeURIComponentData, decodeURIComponentData } from './utils/StrUtils';
import {setStorageSync, getStorageSync} from "./utils/storage";

const host = 'https://admin.cnzxzx.cn/';


import Vue from 'vue'
Vue.config.productionTip = false
Vue.prototype.$host = host;
Vue.prototype.$store = store;
Vue.prototype.$util = util;
Vue.prototype.$time = time;

Vue.prototype.$confirm = util.confirm;
Vue.prototype.$showToast = util.showToast;
Vue.prototype.$showLoading = util.showLoading;
Vue.prototype.$hideLoading = util.hideLoading;
Vue.prototype.$getAssetUrl = util.getAssetUrl;
Vue.prototype.$getWebAssetUrl = util.getWebAssetUrl;

Vue.prototype.$go = util.go;


Vue.prototype.$getNum = util.getNum;
Vue.prototype.$hasAdmin = util.hasAdmin;
Vue.prototype.$getUserId = util.getUserId;
Vue.prototype.$getUserInfo = util.getUserInfo;

Vue.prototype.$getCompanyId = util.getCompanyId;
Vue.prototype.$getCompanyInfo = util.getCompanyInfo;

   // 认证对象
Vue.prototype.$auth = auth;
Vue.prototype.$hasPermi = auth.hasPermi;
Vue.prototype.$hasPermiOr = auth.hasPermiOr;
Vue.prototype.$hasPermiAnd = auth.hasPermiAnd;
Vue.prototype.$hasRole = auth.hasRole;
Vue.prototype.$hasRoleOr = auth.hasRoleOr;
Vue.prototype.$hasRoleAnd = auth.hasRoleAnd;

Vue.prototype.$encodeURIComponent = encodeURIComponentData;

Vue.prototype.$decodeURIComponent = decodeURIComponentData;

Vue.prototype.$setStorageSync = setStorageSync;

Vue.prototype.$getStorageSync = getStorageSync;


Vue.prototype.$getAvatar = (url)=>{
	return util.getAvatar(url);
};



App.mpType = 'app'
Vue.use(uView);

uni.$u.config.unit = 'rpx'

const app = new Vue({
  ...App
})

app.$mount()













