/**
 * 用户相关api
 */
import request from '@/utils/request'


export function logout() {
	return request({
		url: '/logout',
		method: 'delete',
		headers: {
			'auth': true // 需要认证，通过
		}
	})
}

export function getUserInfo() {
	return request({
		url: '/system/user/profile',
		method: 'get',
		headers: {
			'auth': true
		}
	})
}


/**
 * 
 * @returns {SysUser[]}
 */
export function getChildUserList(params) {
	return request({
		url: '/system/user/profile/getChildUserList',
		method: 'get',
		params: params,
		headers: {
			'auth': true
		}
	})
}


/**
 * 根据userId 获取用户信息
 * @param {*} userId 
 * @returns 
 */
export function getUserInfoByUserId(userId) {
	return request({
		url: `/system/user/userdetail/${userId}`,
		method: 'get',
		headers: {
			'auth': true
		}
	})
}

/**
 * 创建新学员， 创建用户
 * @param {*} sysUser 
 * @returns 
 */
export function createUserInfo(sysUser) {
	return request({
		url: `/system/user/addChild`,
		method: 'post',
		data: sysUser,
		headers: {
			'auth': true
		}
	})
}

/**
 * userId
 * parentUserId
 * deptId
 * userName
 * nickName
 * email
 * phonenumber
 * sex
 * 
 * avatar
 * birthday
 * age
 * hobby
 * contactWay
 * parentName
 * address
 * introduction
 * remark
 * password
 * status
 * delFlag
 * Date loginIp
 * loginDate
 * SysDept dept
 * List<SysRole> roles
 * Long[] roleIds
 * Long[] postIds
 * Long roleId
 * 编辑用户或者学员
 * @param {*} sysUser
 * @returns 
 */
export function editUserInfo(sysUser) {
	return request({
		url: `/system/user/update`,
		method: 'put',
		data: sysUser,
		headers: {
			'auth': true
		}
	})
}

export function deleteUser(userIds) {
	return request({
		url: `/system/user/delchild/${userIds}`,
		method: 'delete',
		headers: {
			'auth': true
		}
	})
}

/**
 * 获取学生成长记录分页列表
 * @param {*} userIds 
 * @returns 
 */
export function getUserRecordPage(params) {
	return request({
		url: `/system/class/user/record/page`,
		method: 'get',
		params: params,
		headers: {
			'auth': true
		}
	})
}

/**
 * 创建成长记录
 * @param {*} data 
 * @returns 
 */
 export function reqCreateUserRecord(data) {
	return request({
		url: '/system/class/user/record/create',
		method: 'post',
		data: data,
		// params: params,
		headers: {
			'auth': true // 需要认证，通过
		}
	})
}

/**
 * 获取成长记录
 * @param {*} id 
 * @returns 
 */
 export function reqUserRecordById(id) {
	return request({
		url: '/system/class/user/record/get/' + id,
		method: 'get',
		// data: data,
		// params: params,
		headers: {
			'auth': true // 需要认证，通过
		}
	})
}


// get/{id}



/**
 * 删除成长记录
 * @param {*} data 
 * @returns 
 */
 export function reqDelUserRecord(ids) {
	return request({
		url: `/system/class/user/record/delete/${ids}`,
		method: 'delete',
		// data: data,
		// params: params,
		headers: {
			'auth': true // 需要认证，通过
		}
	})
}


/**
 * 编辑成长记录
 * @param {*} data 
 * @returns 
 */
 export function reqUpdateUserRecord(data) {  
	return request({
		url: `/system/class/user/record/update`,
		method: 'put',
		data: data,
		// params: params,
		headers: {
			'auth': true // 需要认证，通过
		}
	})
}

export function searchUserAndChild(params){
  return request({
		url: `/system/user/listAndChild`,
		method: 'get',
		// data: data,
		params: params,
		headers: {
			'auth': true // 需要认证，通过
		}
	})
}