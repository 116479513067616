<template><App :keepAliveInclude="keepAliveInclude"/></template><script>
	// import {
	// 	base
	// } from './api/index';
	import {
		mapMutations
	} from 'vuex';
	export default {
		methods: {
			...mapMutations(['login'])
		},
		onLaunch: function() {
			/* let userInfo = uni.getStorageSync('userInfo') || '';
			if(userInfo.id){
				//更新登陆状态
				uni.getStorage({
					key: 'userInfo',
					success: (res) => {
						this.login(res.data);
					}
				});
			} */
			console.log('App Launch');
			uni.getSystemInfo({
				success: res => {
					//导航高度
					this.globalData.navHeight = res.statusBarHeight;
				},
				fail(err) {
					console.log(err);
				}
			})

			// 请求meedu的系统配置
/* 			base.config().then(res => {
				this.globalData.user_protocol = res.user_protocol;
				this.globalData.user_private_protocol = res.user_private_protocol;
				this.globalData.aboutus = res.aboutus;
				this.globalData.player_cover = res.player.cover;
				this.globalData.logo = res.logo;
			}) */

			// #ifdef MP
			// 小程序session flash
			uni.login({
				success: (res) => {
					console.log('uni.login res:', res);
					// base.wxLogin({
					// 	code: res.code
					// }).then(res => {
					// 	uni.setStorageSync('openid', res.openid);
					// })
				}
			})
			// #endif


			// #ifdef APP-NVUE
/* 			plus.screen.lockOrientation('portrait-primary');

			let appid = plus.runtime.appid;
			if (appid && appid.toLocaleLowerCase() != "hbuilder") {
				uni.request({
					url: 'https://uniapp.dcloud.io/update', //检查更新的服务器地址
					data: {
						appid: plus.runtime.appid,
						version: plus.runtime.version
					},
					success: (res) => {
						console.log('success', res);
						if (res.statusCode == 200 && res.data.isUpdate) {
							let openUrl = plus.os.name === 'iOS' ? res.data.iOS : res.data.Android;
							// 提醒用户更新
							uni.showModal({
								title: '更新提示',
								content: res.data.note ? res.data.note : '是否选择更新',
								success: (showResult) => {
									if (showResult.confirm) {
										plus.runtime.openURL(openUrl);
									}
								}
							})
						}
					}
				})
			}

			var domModule = weex.requireModule('dom');
			domModule.addRule('fontFace', {
				'fontFamily': "texticons",
				'src': "url('./static/text-icon.ttf')"
			}); */
			// #endif
		},
		onShow: function() {
			console.log('App Show')
		},
		onHide: function() {
			console.log('App Hide')
		}
	}
</script>

<style lang="scss">
	@import "uview-ui/index.scss";
	// @import "uview-ui/index.scss";

	/*每个页面公共css */
	page{
		font-size: 32rpx;
		color: $uni-text-color;
	}

	button[type=primary] {
		background-color: $uni-color-primary;
		color: $uni-text-color-inverse;
	}

	button[type=primary][plain] {
		background-color: transparent;
		border: 1px solid $uni-color-primary;
		color: $uni-color-primary;
	}


	
</style>