const getters = {
	hasLogin: state => state.user.hasLogin,
	nickname: state => state.user.nickname,
	avatar: state => state.user.avatar,
	balance: state => state.user.balance,
	memberId: state => state.user.memberId,
	userId: state => state.user.userId,
  permissions: state => {
    return state.user.curCompanyInfo && state.user.curCompanyInfo.permissions || []; 
  },
  roles: state => {
    return state.user.curCompanyInfo && state.user.curCompanyInfo.roles || [];
  },
}
export default getters
