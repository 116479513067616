import {
	login,
	getUserInfo,
	logout
} from '@/api/user'
import util from '@/utils/util.js'
import { listToMap } from "@/utils/listTrans.js";

const userInfoStr = uni.getStorageSync("userInfo");

let userInfo = {};

if(userInfoStr){
	try {
		userInfo = JSON.parse(userInfoStr);
	} catch (error) {
		userInfo = userInfoStr || userInfo || {};
	}
}

const curUserInfoStr = uni.getStorageSync("curUserInfo");
let curUserInfo = null;
if(curUserInfoStr){
	try {
		curUserInfo = JSON.parse(curUserInfoStr);
	} catch (error) {
		curUserInfo = curUserInfoStr;
	}
}


const curCompanyInfoStr = uni.getStorageSync("curCompanyInfo");
let curCompanyInfo = null;
if(curCompanyInfoStr){
	try {
		curCompanyInfo = JSON.parse(curCompanyInfoStr);
	} catch (error) {
		curCompanyInfo = curCompanyInfoStr;
	}
}




const state = {
	hasLogin: userInfo.userId? true:false,
	nickName: userInfo.nickName,
	avatar: userInfo.avatar,
	balance: 0,
	token: uni.getStorageSync("token") || '',
	userId: userInfo.userId,
	userInfo: userInfo,
	curUserInfo: curUserInfo || userInfo,
	curCompanyInfo: curCompanyInfo || {}
}

const mutations = {
	SET_HAS_LOGIN: (state, hasLogin) => {
		state.hasLogin = hasLogin
	},
	SET_NICKNAME: (state, nickName) => {
		state.nickName = nickName
	},
	SET_AVATAR: (state, avatar) => {
		state.avatar = avatar
	},
	SET_BALANCE: (state, balance) => {
		state.balance = balance
	},
	SET_MEMBERID: (state, memberId) => {
		state.memberId = memberId
	},
	SET_TOKEN: (state, token) => {
		uni.setStorageSync("token", token);
		state.token = token;
		if(token){
			state.hasLogin = true;
		}else{
			state.hasLogin = false;
		}
		
	},
	SET_USERID: (state, userId) => {
		state.userId = userId
	},
	SET_USER_INFO: (state, userInfo) => {
		state.userInfo = userInfo
	},
	SET_CUR_USER_INFO: (state, curUserInfo) => {
		state.curUserInfo = curUserInfo;
		uni.setStorageSync("curUserInfo", JSON.stringify(curUserInfo));
		if(curUserInfo && state.userInfo && curUserInfo.userId == state.userInfo.userId){
			state.userInfo = curUserInfo;
			uni.setStorageSync("userInfo", JSON.stringify(curUserInfo));
		}
	},
	SET_CUR_COMPANY_INFO: (state, curCompanyInfo) => {
		state.curCompanyInfo = curCompanyInfo
		uni.setStorageSync("curCompanyInfo", JSON.stringify(curCompanyInfo));
	}
	
}

const actions = {
	// get user info
	getUserInfo({
		commit,
		state
	}, isLogin) {
		return new Promise((resolve, reject) => {
			getUserInfo().then(response => {
				const {
					data
				} = response

				uni.setStorageSync("userInfo", JSON.stringify(data));

				if (!data) {
					reject('Verification failed, please Login again.')
					return;
				}
				const {
					userId,
					nickName,
					avatar,
					balance
				} = data
				commit('SET_USER_INFO', data);
				if(isLogin){
					commit('SET_CUR_USER_INFO', data);
				}
				commit('SET_NICKNAME', nickName)
				commit('SET_AVATAR', avatar)
				commit('SET_BALANCE', balance)
				commit('SET_USERID', userId)
				resolve(data)
			}).catch(error => {
				reject(error)
			})
		})
	},

	// user logout
	logout({
		commit,
		state,
	}) {
		console.log('logout')
		return new Promise((resolve, reject) => {
			logout().then(() => {
				commit('SET_HAS_LOGIN', false)
				commit('SET_NICKNAME', '')
				commit('SET_AVATAR', '')
				commit('SET_BALANCE', '')
				commit('SET_MEMBERID', '')
				commit('SET_TOKEN', null)
				commit('SET_USERID', null)
				commit('SET_USER_INFO', null)
				commit('SET_CUR_USER_INFO', null)
				commit('SET_CUR_COMPANY_INFO', null)
				uni.setStorageSync("userInfo", null);
				util.clearStorageSync();
				resolve()
			}).catch(error => {
				console.error(error)
				reject(error)
			})
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
