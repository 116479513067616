export function encodeURIComponentData(data, keyList=[]){
  var newData = {...(data || {})};
  if(typeof keyList === "string"){
    keyList = [keyList];
  }

  for(var i = 0; i < keyList.length; i++){
    if(newData[keyList[i]]){
      newData[keyList[i]] = encodeURIComponent(newData[keyList[i]]);
    }
    
  }

  return newData;
}

export function decodeURIComponentData(data, keyList=[]){
  var newData = {...(data || {})};
  if(typeof keyList === "string"){
    keyList = [keyList];
  }

  for(var i = 0; i < keyList.length; i++){
    if(newData[keyList[i]]){
      newData[keyList[i]] = decodeURIComponent(newData[keyList[i]]);
    }
    
  }

  return newData;
}
