export function listToMap(list, key="id", value=""){
  const mapData = {};
  if(list && list.length){
    for(var i=0;i<list.length;i++){
      const item = list[i];
      if(item){
        if(value){
          mapData[item[key]] = item[value];
        }else{
          mapData[item[key]] = item;
        }

      }
    }
  }
  return mapData;
}
