import { render, staticRenderFns, recyclableRender, components } from "./right-window.vue?vue&type=template&id=195392b6&scoped=true&"
var renderjs
import script from "./right-window.vue?vue&type=script&lang=js&"
export * from "./right-window.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@dcloudio/vue-cli-plugin-uni/packages/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195392b6",
  null,
  false,
  components,
  renderjs
)

export default component.exports